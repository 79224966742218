<template>
  <div>
    <div style="height: 10px"></div>
    <van-cell title="隐藏协议" is-link to="/protocol/privacyAgreement" />
    <van-cell title="用户协议" is-link to="/protocol/userAgreement" />
    <van-cell title="儿童隐私协议" is-link to="/protocol/childrenSPrivacyAgreement" />
    <van-cell title="关于我们" is-link to="index" />
    <van-cell title="撤销协议授权" is-link to="index" />
    <div style="height: 10px"></div>
    <van-button type="primary" block>退出登录</van-button>
  </div>
</template>
<script>
export default {
  name: "set"
}
</script>



<style scoped lang="less">

</style>
